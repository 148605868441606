<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <div
            class="flex border-2 border-green-1002 flex px-3 py-1 rounded-lg w-min"
          >
            <router-link
              :to="{
                name: 'participant-curriculum',
                params: { participant: participant }
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="32"
                height="32"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
          </div>
          <form class="mt-4" @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Novo Curso Complementar</h2>

            <div class="flex flex-col">
              <label class="mb-2" for="name">
                *Titulo do curso:
                <span v-if="$v.name.$error">
                  <span class="error" v-if="!$v.name.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.name.maxLength">
                    O nome deve ter no máximo
                    {{ $v.name.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.name.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="name"
                type="text"
                placeholder="Nome"
                autocomplete="off"
              />
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="issuer">
                *Emissor:
                <span v-if="$v.issuer.$error">
                  <span class="error" v-if="!$v.issuer.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.issuer.maxLength">
                    O emissor deve ter no máximo
                    {{ $v.issuer.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.issuer.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="issuer"
                type="text"
                placeholder="Emissor"
                autocomplete="off"
              />
            </div>
            <div class="flex-col">
              <label class="mb-2" for="issued-at">
                *Data de Emissão:
                <span v-if="$v.issuedAt.$error">
                  <span class="error" v-if="!$v.issuedAt.required">
                    Campo obrigatório.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.issuedAt.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="issued-at"
                type="text"
                placeholder="Data de Emissão"
                v-mask="'##/##/####'"
                autocomplete="off"
              />
            </div>

            <div class="flex-col">
              <label class="mb-2" for="workload">
                *Carga Horária:
                <span v-if="$v.workload.$error">
                  <span class="error" v-if="!$v.workload.required">
                    Campo obrigatório.
                  </span>
                </span>
              </label>
              <input
                v-model="workload"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="workload"
                type="text"
                placeholder="Carga Horária"
                autocomplete="off"
              />
            </div>

            <div class="flex justify-between">
              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import axios from '@/utils/axios';
import DashboardLayout from '../../../../layouts/DashboardLayout';

export default {
  name: 'ComplementaryCourseCreate',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Curso Complementar para o Talento`;
  },

  components: { DashboardLayout },
  directives: {
    mask
  },

  props: {
    participant: {
      required: true,
      type: Number
    }
  },

  data() {
    return {
      name: '',
      issuer: '',
      issuedAt: '',
      workload: ''
    };
  },

  validations: {
    name: {
      required,
      maxLength: maxLength(255)
    },
    issuer: {
      required,
      maxLength: maxLength(255)
    },
    issuedAt: {
      required
    },
    workload: {
      required
    }
  },

  methods: {
    submit() {
      if (this.$v.$invalid) {
        this.$v.name.$touch();
        this.$v.issuer.$touch();
        this.$v.issuedAt.$touch();
        this.$v.workload.$touch();
      } else {
        let attributes = {
          name: this.name,
          issuer: this.issuer,
          issued_at: this.issuedAt,
          workload: this.workload
        };

        axios
          .post(`/api/cursos-complementares/${this.participant}`, attributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'participant-curriculum',
              params: {
                participant: this.participant
              }
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    }
  }
};
</script>

<style scoped></style>
